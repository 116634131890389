import React, { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const PasswordOfProductForUrlPassword = () => {
  const location = useLocation();
  const [error, setError] = useState(null); // State to manage error messages

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const devHost = '13.127.111.27'; // Use only the common part for both HTTP and HTTPS

// Check if the window location starts with the devHost
const backendUrl = window.location.origin.startsWith(`https://${devHost}`) || window.location.origin.startsWith(`http://${devHost}`)
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_PROD_URL;

  console.log("Backend URL:", backendUrl);

  const handleChange = async (event) => {
    const password = event.target.value;

    try {
      const response = await axios.get(
        `${backendUrl}/purchasedQrForUrl/${id}`
      );
      if (response.data && password === response.data.QrCodePassword) {
        window.location.href = response.data.UrlText;
      } else {
        setError("Incorrect password. Please try again."); // Set error message if password is incorrect
      }
    } catch (err) {
      console.error("Error fetching password:", err);
      setError("An error occurred while verifying the password."); // Handle error if the request fails
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-full h-auto flex flex-col p-4 justify-center items-center rounded-[11px] m-[25px] p-[16px] shadow-[0px_5px_15px_rgba(0,0,0,0.35)]">
        <input
          type="password"
          className="m-4 p-2 border border-solid border-[#EBEBEB] rounded"
          placeholder="Enter Your Password"
          required
          onChange={handleChange}
        />
        {error && <div className="text-red-500 text-sm">{error}</div>}{" "}
        {/* Display error message if exists */}
      </div>
    </div>
  );
};

export default PasswordOfProductForUrlPassword;
