import { useEffect, useState, useRef } from "react";
import NavBar from "../NavBar";
import QRCodeStyling from "qr-code-styling";
import Footer from "../Footer";
import ScanQRBox from "../DialogueBoxComponent/ScanQRBox";
import styles from "../module/navbar.module.css";
import { useSelector, useDispatch } from "react-redux";
import CustomizableQrCodewindow from "./CustomizableQrCodewindow";
import { setData, setReset }  from "../store/QrCodeCustmizable/QrCodecustmizableSlicer";
import { useLocation } from "react-router-dom";
import ServicesForQrCode from "./ServicesForQrCode";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import useLoadScript from '../reusableHook/useLoadScript';
import axios from "axios";
import { setDownloadText } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";
import { setPlanName, setPrice } from "../store/chooseBestOffers/BestOffersSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";

const GenerateQrCodeServices = ({ serviceName }) => {
  const [filetype, setFileType] = useState("png");
  const [switchButton, setSwitchButton] = useState(true);

  const [openQRScanner, setQrScanner] = useState(false);
  const qrCodeRef = useRef(null);
  const qrCodeInstance = useRef(null);
  const qrCodeState = useSelector((state) => state.QrCodeCustmizable);
  const userProfile = useSelector((state) => state.profile);
  const [isLogin, setIsLogin] = useState(false);

  const [openDialogbox, setIsDialogbox] = useState(false);
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const name = query.get("name");
  const { loaded, error } = useLoadScript("https://checkout.razorpay.com/v1/checkout.js");
  const [showAlert,setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");


  const devHost = '13.127.111.27'; // Use only the common part for both HTTP and HTTPS

  // Check if the window location starts with the devHost
  const backendUrl = window.location.origin.startsWith(`https://${devHost}`) || window.location.origin.startsWith(`http://${devHost}`)
      ? process.env.REACT_APP_API_DEV_URL
      : process.env.REACT_APP_API_PROD_URL;

  console.log("Backend URL:", backendUrl);



useEffect(() => {
  if (!qrCodeRef.current) return;

  qrCodeInstance.current = new QRCodeStyling({
      width: qrCodeInstance.width,
      height: qrCodeInstance.height,
      margin: qrCodeState.margin,
      image: qrCodeState.image, // Use state image
      data: qrCodeState.data,
      dotsOptions: {
          color: qrCodeState.dotsOptions.color,
          type: qrCodeState.dotsOptions.type,
      },
      cornersDotOptions: {
          color: qrCodeState.cornersDotOptions.color,
          type: qrCodeState.cornersDotOptions.type,
      },
      cornersSquareOptions: {
          color: qrCodeState.cornersSquareOptions.color,
          type: qrCodeState.cornersSquareOptions.type,
      },
      backgroundOptions: {
          color: qrCodeState.backgroundOptions.color,
      },
      imageOptions: {
          crossOrigin: qrCodeState.imageOptions.crossOrigin,
          margin: qrCodeState.imageOptions.margin,
          imageSize: qrCodeState.imageOptions.imageSize,
          hideBackgroundDots: qrCodeState.imageOptions.hideBackgroundDots,
      },
       errorCorrectionLevel: 'H'
  });

  qrCodeInstance.current.append(qrCodeRef.current);

  return () => {
      qrCodeInstance.current = null;
  };
}, [qrCodeState]);

// Update QR code instance when state changes
useEffect(() => {
  if (qrCodeInstance.current) {
      qrCodeInstance.current.update({
          width: qrCodeState.width,
          height: qrCodeState.height,
          image: qrCodeState.image,
          margin: qrCodeState.margin,
          data: qrCodeState.data,
          dotsOptions: {
              color: qrCodeState.dotsOptions.color,
              type: qrCodeState.dotsOptions.type,
          },
          cornersDotOptions: {
              color: qrCodeState.cornersDotOptions.color,
              type: qrCodeState.cornersDotOptions.type,
          },
          cornersSquareOptions: {
              color: qrCodeState.cornersSquareOptions.color,
              type: qrCodeState.cornersSquareOptions.type,
          },
          backgroundOptions: {
              color: qrCodeState.backgroundOptions.color,
          },
          imageOptions: {
              crossOrigin: qrCodeState.imageOptions.crossOrigin,
              margin: qrCodeState.imageOptions.margin,
              imageSize: qrCodeState.imageOptions.imageSize,
              hideBackgroundDots: qrCodeState.imageOptions.hideBackgroundDots,
          },
           errorCorrectionLevel: 'H'
      });
  }
}, [qrCodeState]);



  const onExtensionChange = (e) => {
    setFileType(e.target.value);
  };



  
  const serviceInstanceId = useSelector((state) => state.serviceInstance.serviceId);

  const bestPlans = useSelector((state) => state.Bestplans);
  const downloadText = useSelector((state)=> state.downloadText.downloadText);
  
  
  const serviceInstanceUrl = useSelector((state) => state.serviceInstance.serviceId);


const profile = useSelector((state)=>state.profile);


useEffect(()=>{
  const price = JSON.parse(localStorage.getItem('price'));
  const planName = JSON.parse(localStorage.getItem('planName'));
  dispatch(setPrice(price));
  dispatch(setPlanName(planName));
})

const handlePayment = () => {
  // Check if the user is logged in
  if (userProfile.username !== '') {

    // Check if Razorpay script is loaded
    if (!loaded) {
      alert('Razorpay SDK is still loading...');
      return;
    }

    // Check if there was an error loading Razorpay script
    if (error) {
      alert('Failed to load Razorpay SDK. Please try again.');
      return;
    }

    
    // Razorpay payment options
    const options = {
      key: 'rzp_test_1SwlDt8len52x8', //'rzp_live_LwthtEns6Yq7za',  //rzp_test_1SwlDt8len52x8
      amount: bestPlans.price*100, 
      currency: "INR",
      name: `ZM QR Code Service You choose${bestPlans.planName}`,
      description: "Test Transaction for QR Code",
      handler: async function (response) {
        alert("Payment Successful! Payment ID: " + response.razorpay_payment_id);
        try {
          const formData = {
            username: profile.username,
            email: profile.email,
            planName: bestPlans.planName,
            price: bestPlans.price,
            transationId: response.razorpay_payment_id,
          };
        
          // POST request with formData
          const paymentRemainder = await axios.post(`${backendUrl}/paymentDone`, formData);
          if(paymentRemainder.status===201){
            setShowAlert(true);
            setAlertMessage(`Thank you choosing Our Plan ${bestPlans.planName} , price: ${bestPlans.price}`);
          }
        
          localStorage.removeItem('price');
          localStorage.removeItem('planName');
        } catch (error) {// Handle the error
          setShowAlert(true);
          setAlertMessage(error);
        }
 
        dispatch(setData(serviceInstanceId));
       
      },
      prefill: {
        name: userProfile.username,
        email: userProfile.email,
      },
      theme: {
        color: "#F37254",
      },
    };

    // Open the Razorpay payment modal
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  } else {
    // If the user is not logged in, show the login modal
    setIsLogin(true);
  }
};


  // Effect to trigger download when qrCodeState.data changes

  useEffect(() => {
    if (qrCodeState.data && qrCodeState.data !== 'https://zmqrcode.com/') {
        download(); // Automatically download the QR code when data is updated and not the default
    }
},[qrCodeState.data]); // Include download in dependencies

useEffect(() => {
  const serviceId = localStorage.getItem('serviceInstanceId'); // Get the item as a string
  
  // Check if serviceId exists and is a valid JSON
  if (serviceId) {
    try {
      const parsedServiceId = JSON.parse(serviceId); // Parse it if it exists
      dispatch(setInstanceId(parsedServiceId)); // Dispatch action with the parsed ID
    } catch (error) {
      console.error("Error parsing serviceInstanceId:", error);
      // Handle parsing error if needed
    }
  }
}, [dispatch]);


const download = async () => {

  
  if (qrCodeRef.current) {
    // Create a new QRCodeStyling instance with the latest state
    const qrCodeToDownload = new QRCodeStyling({
      width: qrCodeState.width,
      height: qrCodeState.height,
      image: qrCodeState.image,
      margin: qrCodeState.margin,
      data: qrCodeState.data,
      dotsOptions: qrCodeState.dotsOptions,
      cornersDotOptions: qrCodeState.cornersDotOptions,
      cornersSquareOptions: qrCodeState.cornersSquareOptions,
      backgroundOptions: qrCodeState.backgroundOptions,
      imageOptions: qrCodeState.imageOptions,
      errorCorrectionLevel: "H",
    });

    // Append QR code to a temporary DOM element to render it
    const tempDiv = document.createElement('div');
    qrCodeToDownload.append(tempDiv);

    try {
      // Wait for the QR code to render before attempting download
      qrCodeToDownload.download({ extension: filetype });

      // Generate raw data (blob) for the image
      const blob = await qrCodeToDownload.getRawData("png");
      
      if (blob) {
        const qrFile = new File([blob], "qrcode.png", { type: "image/png" });

        // Prepare the file for upload using FormData
        const formData = new FormData();
        formData.append("file", qrFile); // Add the file to FormData

        

        const id = serviceInstanceUrl.split("id=")[1];
        // Post the file to the server

        const responseData = await axios.post(
          name === 'Url' 
            ? `${backendUrl}/saveqrcodeimageForUrl?id=${id}` 
            : `${backendUrl}/saveqrcodeimage?id=${id}`, // URL resolved correctly
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Required for sending files
            },
          }
        );
        localStorage.removeItem('serviceInstanceId');
        localStorage.removeItem('price');
        localStorage.removeItem('planName');
        dispatch(setDownloadText('Downloaded'))
        console.log(responseData.status)
      } else {
        console.error("Error: Blob data could not be generated.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      // Clean up the temporary element
      tempDiv.remove();
      dispatch(setReset());
      // Dispatch reset action if needed
    }
  }
};



  const openClickQRScanner = () => {
    setQrScanner(!openQRScanner);
  };

  const handleOpenDialogForcustomizableforShape = () => {
    setIsDialogbox(true);
    setType("Shapes");
  };
;
  const handleOpenDialogForcustomizableforLogo = () => {
    setIsDialogbox(true);
    setType("Logo");
  };

  const handleOpenDialogForcustomizableforShapeClose = () => {
    setIsDialogbox(false);
  };





  return (
    <div>
      <NavBar />
      <div
        className="flex flex-col rounded-lg justify-center p-4 bg-white w-full"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <button
          className="text-[#1D91AA] border-none underline font-raleway font-semibold m-4 p-2 flex flex-start"
          onClick={openClickQRScanner}
        >
          ZM QR CODE SCANNER
        </button>

        {openQRScanner && <ScanQRBox handleClose={openClickQRScanner} />}

        <div className="pl-4 pr-4 pb-4">
          <h3 className="font-raleway text-base font-semibold">
            {serviceName}
          </h3>
          <div className={styles.QRgeneratedContainer}>
            <div
              className={styles.QRgeneratedContainerCard}
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              <ServicesForQrCode name={name} />
            </div>
            <div className="flex flex-col p-4 m-4 justify-center items-center">
              <div ref={qrCodeRef} style={{ borderRadius: "50%" }}></div>
              <label>
                <select
                  onChange={onExtensionChange}
                  value={filetype}
                  className="w-auto rounded-lg border border-gray-400 border-opacity-50 focus:outline-none"
                >
                  <option value="png">PNG</option>
                  <option value="jpeg">JPEG</option>
                  <option value="webp">WEBP</option>
                </select>
              </label>
              <button
                className="w-full p-2 font-raleway text-white rounded-md my-4 text-sm bg-[#1D91AA]"
                onClick={handlePayment}
              >
                {downloadText===''?'Download':downloadText}
              </button>
              <div className="flex justify-center items-center">
                <div className="flex flex-row m-4 p-4">
                  <button
                    className="bg-[#fff] border-none text-customBlue font-ralway m-2 p-2"
                    onClick={handleOpenDialogForcustomizableforShape}
                  >
                    Qr Code Shape
                  </button>
                  <button
                    className="bg-[#fff] border-none text-customBlue font-ralway m-2 p-2"
                    onClick={handleOpenDialogForcustomizableforLogo}
                  >
                    Logos
                  </button>
                </div>
                {openDialogbox && (
                  <CustomizableQrCodewindow
                    onClose={handleOpenDialogForcustomizableforShapeClose}
                    CustomizableQrCodewindowChoice={type}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && <CustomAlert message={alertMessage} onClose={()=>{setShowAlert(false)}}/>}
      <div>
        {isLogin && (
          <div className="flex justify-center items-center h-screen">
            {/* Trigger button */}
            <button
              onClick={() => setIsLogin(true)}
              className="bg-blue-500 text-white p-3 rounded-lg"
            >
              Open Sign-Up/Login
            </button>
            

            {/* Modal */}
            { ((userProfile.username === '') && isLogin) && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div className="bg-white p-8 rounded-lg w-96">
                  <div>
                    {/* Switch buttons */}
                    <div className="flex justify-end">
                    <button
                      onClick={() => setIsLogin(false)}
                      className="text-gray-600 hover:text-red-500"
                    >
                      X
                    </button>
                  </div>
                      <div className={styles.formsignupandlogin}>
                        <div className="flex flex-row">
                          <button
                          className={switchButton ? styles.selected : styles.notselected} style={{
                            fontSize: "14px", // font-size becomes fontSize
                            margin: "15px",   // margin
                            padding: "15px",  // padding
                          }}
                            onClick={() => setSwitchButton(true)}
                          >
                            Sign Up
                          </button>
                          <button
                          className={!switchButton ? styles.selected : styles.notselected} 
                            onClick={() => setSwitchButton(false)}
                            
                          >
                            Login
                          </button>
                        </div>
                      </div>
                 

                    {/* Conditional Form Rendering */}
                    {switchButton ? <SignUp /> : <LoginUp />}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default GenerateQrCodeServices;
