import React from "react";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import AboutUsHomePage from "./AboutUsHomePage";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

const RefundPolicy = () => {
    return (
        <div>
            <NavBar />
            <div className="bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
                    <h2 className="text-3xl font-bold mb-4 text-gray-800 text-center">
                        Refund Policy
                    </h2>
                    <p className="text-gray-600 mb-6">
                        We appreciate your purchase, and we are here to assist you if you're not satisfied with your QR code purchase. Please read the details of our refund policy below.
                    </p>
                    <div className="flex justify-center items-center font-raleway flex-col">
                        <h3 className="text-xl font-semibold text-gray-700 mb-2">
                            Refund Eligibility:
                        </h3>
                        <ol className="list-decimal list-inside mb-6 text-gray-600 flex flex-col justify-center items-start">
                            <li>QR codes must be returned within 30 days of delivery.</li>
                            <li>The QR code must be unused and in the same condition as received.</li>
                            <li>Proof of purchase is required for all refunds.</li>
                            <li>Files associated with the QR code should not exceed 5 MB.</li>
                        </ol>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">
                        Refund Process:
                    </h3>
                    <p className="text-gray-600 mb-6">
                        To initiate a refund, please contact us at <Link to="/contact-us" className="text-blue-500 underline">
                            Contact Us
                        </Link>. You must reach out within 24 hours of receiving the QR code to be eligible for a refund. Once your return is received and inspected, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed within 5-7 business days.
                    </p>
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">
                        Refund Method:
                    </h3>
                    <p className="text-gray-600 mb-6">
                        Refunds will be credited back to your original method of payment, including credit/debit card or other payment methods used during the purchase.
                    </p>
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">Exceptions:</h3>
                    <p className="text-gray-600 mb-6">
                        Some items are non-refundable, such as downloadable software or digital goods. Please contact our support for more information.
                    </p>
                    <p className="text-gray-600 text-sm italic text-center">
                        For any further questions or clarifications regarding our refund policy, please reach out to us at{" "}
                        <Link to="/contact-us" className="text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded m-4">
                            Contact Us
                        </Link>. We are here to assist you.
                    </p>
                </div>
            </div>
            <AboutUsHomePage />
            <ContactUs />
            <Footer />
        </div>
    );
};

export default RefundPolicy;
