import { faUser, faBagShopping, faQrcode, faHeadset, faShareSquare, faSignOut, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sidebarInfo from '../resources/sidebarInfo.json';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { resetProfile } from '../store/userProfile/userProfileActions';

// Create a mapping between icon names in the JSON and the actual FontAwesome icons
const iconMapping = {
    faUser: faUser,
    faBagShopping: faBagShopping,
    faQrcode: faQrcode,
    faHeadset: faHeadset,
    faShareSquare: faShareSquare,
    faSignOut: faSignOut,
    faFolder: faFolder
};

const UserProfileSideBar = () => {
    const profile = useSelector(state => state.profile);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (profile.username === "") {
            navigate("/");
        }
    }, [profile.username, navigate]);

    const handleLogout = () => {
        dispatch(resetProfile());
        localStorage.removeItem('profile');
        navigate("/");
    };

    return (
        <div className="flex bg-customBlue w-[20%] h-[655px] rounded-lg shadow-lg p-4 m-4">
            <ul className="list-none flex flex-col">
                {sidebarInfo.map((item, index) => {
                    const isActive = location.pathname === item.Link;
                    return (
                        <li
                            key={index}
                            className={`flex w-full items-center p-4 rounded-md m-2 transition-colors duration-300
                                ${isActive ? 'bg-white text-customBlue border-2 border-customBlue' : 'text-white border-2 border-transparent'}
                            `}
                        >
                            {item.name === "Logout" ? (
                                <button onClick={handleLogout} className="flex items-center w-full text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded">
                                    <FontAwesomeIcon
                                        icon={iconMapping[item.Icon]}
                                        className="transition-colors p-2 duration-300 hover:bg-white hover:text-customBlue"
                                    />
                                    <span className="flex items-center w-full text-white text-[15px] p-4 hover:bg-white hover:text-customBlue rounded">
                                        {item.name}
                                    </span>
                                </button>
                            ) : item.name === "Customer Support" ? (
                                <Link to={'/contact-us'}    >
                                     <FontAwesomeIcon
                                        icon={iconMapping[item.Icon]}
                                        className="transition-colors p-1 duration-300 hover:bg-white hover:text-customBlue"
                                    />
                                    <span className="font-raleway text-[18px] transition-colors duration-300 pr-3 hover:'text-customBlue' 'text-white'">
                                        {item.name}
                                    </span>
                                </Link>
                            ) : (
                                <Link to={item.Link} className="flex items-center w-full">
                                    <FontAwesomeIcon
                                        icon={iconMapping[item.Icon]}
                                        className={`transition-colors p-2 duration-300 ${isActive ? 'text-customBlue' : 'text-white'}`}
                                    />
                                    <span className={`font-raleway text-[18px] transition-colors duration-300 ${isActive ? 'text-customBlue' : 'text-white'}`}>
                                        {item.name}
                                    </span>
                                </Link>
                            )}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default UserProfileSideBar;
