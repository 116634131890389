import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './NavBar';
import AboutUsHomePage from './AboutUsHomePage';
import ContactUs from './ContactUs';
import Footer from './Footer';
import '../components/styles/faq.css';

const FAQ = () => {
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [accounts, setAccounts] = useState([]);

    const toggleQuestion = (index) => {
        setActiveQuestion(prevIndex => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        setAccounts([
            { qn: "After a QR code is generated, is it possible to add other content to it?", ans: "Before generating a QR code, it is possible to add multiple types of content. However, once the QR code is generated, it cannot be modified to include additional content. Therefore, it is essential to ensure all necessary information is included before the QR code is created." },
            { qn: "Using a single mobile number or email, how many QR codes can we generate?", ans: "There is no limit to the number of QR codes you can generate using a single mobile number or email. You can create as many QR codes as needed for various purposes without any restrictions." },
            { qn: "Can we generate a QR code without a password?", ans: "Yes, QR codes can be generated with or without a password. Depending on the level of security you require, you can choose to protect the QR code with a password or leave it unprotected for easy access." },
            { qn: "Can we download an invoice through a QR code?", ans: "Yes, you can download an invoice through a QR code. In addition to invoices, our QR code services allow you to access various other documents and services, making it a versatile tool for business operations." },
            { qn: "How can I contact customer support for QR codes?", ans: "You can contact our customer support team by visiting our website and obtaining the contact details provided there. Alternatively, you can send us an email at connect@zmqrcode.com, and our support team will assist you with your queries and concerns." },
            { qn: "Can I make payments using a QR code?", ans: "Yes, you can easily make payments using our QR code. This feature enables quick and secure transactions, making it convenient for both businesses and customers." },
            { qn: "Can I delete my saved data on a QR code?", ans: "No, once a QR code is generated, it is not possible to delete the data it contains. The QR code remains static with the information encoded in it at the time of creation." },
            { qn: "Will I get a refund if I cancel my subscription?", ans: "No, refunds are not provided if you cancel your subscription. It is advisable to review our subscription terms and ensure the service meets your needs before committing." },
            { qn: "If I delete my QR account, will my data get erased?", ans: "Your data will be stored until the validity period is completed. Once the validity period expires, your data will be erased. Deleting your QR account does not immediately erase your data." },
            { qn: "After the plan expires, what should I do?", ans: "After your plan expires, you should renew your account using the options available on your dashboard. Renewing your plan will ensure continued access to our QR code services and prevent any loss of data." },
            { qn: "What is the validity of a QR code?", ans: "The validity of a QR code is typically one year. After this period, you may need to renew the QR code to ensure it remains active and functional." },
            { qn: "How much does it cost to renew my QR code annually?", ans: "The annual renewal cost for a QR code is Rs. 99. This fee ensures that your QR code remains active and you continue to have access to our services." },
            { qn: "Will I lose my data if I don’t renew my QR code?", ans: "Yes, if you do not renew your QR code, you will lose your data after the validity period expires. It is important to renew your QR code within the specified period to retain access to your data and services." },
            { qn: "Can I use my QR code for multiple services?", ans: "Yes, you can use a single QR code for multiple services. This versatility allows you to streamline various business functions and enhance the efficiency of your operations using a single QR code." },
            { qn: "How do I create an account with ZMQRCode Services?", ans: "To create an account, click on the register link on our website, fill in the required details, and submit the form. You will receive a confirmation email to verify your account." },
            { qn: "What information do I need to provide to create an account?", ans: "You need to provide your name, email address, and create a password." },
            { qn: "I forgot my password. How can I reset it?", ans: "Click on the 'Forgot Password' link on the login page, enter your registered email address, and follow the instructions sent to your email to reset your password." },
            { qn: "How do I update my account information?", ans: "Log in to your account, go to the 'Account Settings' section, and update your personal details, contact information, or password as needed." },
            { qn: "Can I change my email address associated with my account?", ans: "Yes, you can change your email address in the 'Account Settings' section of your account dashboard." }
        ]);
    }, []);

    return (
        <div className="flex flex-col">
            <NavBar />
            <div className="flex justify-start p-2">
                <Link to="/" className="text-[16px] underline decoration-[#1D91AA]">
                    Home
                </Link>
            </div>
            <div className="flex flex-col items-center shadow-[0_5px_15px_rgba(0,0,0,0.35)] m-4 rounded-[11px] p-4">
                <div id='faq' className="faq-section">
                    <h2 className="text-2xl font-bold">Frequently Asked Questions</h2>
                    <div className="faq-items mt-4">
                        {accounts.map((item, index) => (
                            <div className="faq-item" key={index}>
                                <div
                                    className={`faq-question ${activeQuestion === index ? 'active' : ''}`}
                                    onClick={() => toggleQuestion(index)}
                                >
                                    {item.qn}
                                </div>
                                {activeQuestion === index && (
                                    <div className="faq-answer mt-2">
                                        {item.ans}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap gap-4 justify-center m-4">
                {[
                    { to: "/comingsoon", title: "2D Barcodes", description: "Everything You Need to Know" },
                    { to: "/comingsoon", title: "Chatbot", description: "Discover Our Chatbot Features" },
                    { to: "/comingsoon", title: "Getting Started", description: "Your Guide to QR Codes" },
                    { to: "/comingsoon", title: "Setting Up Your Account", description: "Simple Steps for Account Setup" },
                    { to: "/comingsoon", title: "14-Day Free Trial", description: "Experience Our Services Free for 14 Days" },
                    { to: "/comingsoon", title: "Plan Information", description: "Details on Our Subscription Plans" },
                    { to: "/comingsoon", title: "Managing Your Subscription", description: "Subscription Management Made Easy" },
                    { to: "/comingsoon", title: "Pricing and Billing", description: "Understanding Our Pricing and Billing" },
                    { to: "/comingsoon", title: "Managing Your QR Codes", description: "Organize and Control Your QR Codes" },
                    { to: "/comingsoon", title: "Designing Your QR Codes", description: "Customize Your QR Codes with Style" },
                    { to: "/comingsoon", title: "Tracking Your QR Codes", description: "Learn About Tracking and Analytics" }
                ].map((item, index) => (
                    <li className="flex flex-col bg-white p-4 shadow-[0_5px_15px_rgba(0,0,0,0.35)] rounded-[11px] m-4 transition-all hover:scale-105" key={index}>
                        <Link to={item.to}>
                            <h4 className="text-2xl font-bold font-raleway">{item.title}</h4>
                            <p className="text-[18px] font-bold font-raleway text-center">{item.description}</p>
                        </Link>
                    </li>
                ))}
            </div>
            <AboutUsHomePage />
            <ContactUs />
            <Footer />
        </div>
    );
};

export default FAQ;
