import { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setCornersDotColor, setCornersSquareColor, setDotsColor } from '../store/QrCodeCustmizable/QrCodecustmizableSlicer';


const CustomizableColorForBody = () => {
    const [color, setColor] = useState("#000000"); // State for selected color
    const colorInputRef = useRef(null);
    // State for color type
    const dispatch = useDispatch();

    // Handle color type selection
    // Handle color change from color picker
    const handleColorChange = (event) => {
        const newColor = event.target.value;
        setColor(newColor);

            dispatch(setDotsColor(color)); 
            dispatch(setCornersDotColor(color));
            dispatch(setCornersSquareColor(color))  
            // Update color if 'single' type is selected
    };

    // Handle icon click to open color picker
    const handleIconClick = () => {
        if (colorInputRef.current) {
            colorInputRef.current.click();
        }
    };

    return (
        <div className="flex flex-col p-2 m-2 border-b-2 border-[#f0ebeb] rounded-[11px] w-full">
            <h3 className="font-raleway text-sm cursor-pointer text-black flex justify-start font-bold">
                Choose QR Code Body Color
            </h3>
            <div className='border-b-2 border-[#f0ebeb] m-[5px] rounded-[49%] bg-[#6c7277] w-[38px] flex justify-center items-center'>
              
              <div className='p-2 flex justify-start relative'>
                  <FontAwesomeIcon
                      icon={faPaintbrush}
                      size="1x"
                      className="cursor-pointer text-[#122323] bg-transparent shadow-none mt-1"
                      onClick={handleIconClick}
                  />
                  <input
                      type='color'
                      ref={colorInputRef}
                      onChange={handleColorChange}
                      value={color}
                      style={{
                          position: 'absolute',
                          top: '100%', // Position below the icon
                          left: 0, // Align with the icon
                          zIndex: 10,
                          opacity: 0 // Hide the input but keep it interactable
                      }}
                  />
              </div>
      </div>
</div>
    );
};

export default CustomizableColorForBody;
