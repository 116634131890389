import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle} from '@fortawesome/free-brands-svg-icons';

const devHost = '13.127.111.27'; // Use only the common part for both HTTP and HTTPS

// Check if the window location starts with the devHost
const backendUrl = window.location.origin.startsWith(`https://${devHost}`) || window.location.origin.startsWith(`http://${devHost}`)
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_PROD_URL;

console.log("Backend URL:", backendUrl);

const GoogleLoginButton = () => {
  const handleLogin = () => {
    window.location.href = `${backendUrl}/auth/google`;
    
  };

  return (
    <button
      className="flex items-center justify-center p-2 border rounded-lg text-white"
      style={{ backgroundColor: '#4285F4' }}
      onClick={handleLogin}
    >
      <FontAwesomeIcon icon={faGoogle} className="mr-2" />
      <span>Sign in with Google</span>
    </button>
  );
};

export default GoogleLoginButton;