import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import LoginUp from "../UserRegistrayion/LoginUp";
import SignUp from "../UserRegistrayion/SignUP";
import convertBitsToMb from "../convertbits/convertBitsToMb";
import convertBitsToGB from "../convertbits/convertbitstoGb";
import convertBitsToTB from "../convertbits/convertBitsToTB";
import {
  setPlanName,
  setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import CustomAlert from "../CustomAlertForSubmision";

const DocumentUploadForm = () => {
  const fileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [files, setFiles] = useState([]);
  const [switchButton, setSwitchButton] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const [filePaths, setFilePath] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [buttonText, setButtonText] = useState("Submit");
  const [alertMessage,setAlertMessage] = useState("");
  const [showAlert,setShowAlert] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  localStorage.removeItem('price');
  localStorage.removeItem('planName');

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get("name");
    if (queryParam) {
      setServiceName(queryParam);
    }
  }, [location]);

  useEffect(() => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0); // Total size in bytes
    if ((totalSize>0)&&(totalSize <= 52428800)) {
      const plan = convertBitsToMb(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if ((totalSize > 52428800)&&(totalSize <= 96262144000)) {
      const plan = convertBitsToGB(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    } else if ((totalSize > 96262144000)&&(totalSize > 96262144000)) {
      const plan = convertBitsToTB(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
    }
  }, [files, dispatch]);


  const devHost = '13.127.111.27'; // Use only the common part for both HTTP and HTTPS

  // Check if the window location starts with the devHost
  const backendUrl = window.location.origin.startsWith(`https://${devHost}`) || window.location.origin.startsWith(`http://${devHost}`)
      ? process.env.REACT_APP_API_DEV_URL
      : process.env.REACT_APP_API_PROD_URL;;

  console.log("Backend URL:", backendUrl);


  const chooseFileHandleChange = async (event, setFieldValue) => {
    setButtonText("Submit");
    if (profile.username !== "") {
      
      setLoading(true);
      const selectedFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setFieldValue("files", selectedFiles);

      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append("file", file); // Make sure 'file' matches the expected field name in the backend
      });

     
      try {
        const uploadData = await postUploadFile(
          `${backendUrl}/upload`,
          formData
        );
  
        const data = uploadData.data;
  
        if (Array.isArray(data)) {
          const newFilePaths = data.map((item) => item.url);
  
          setFilePath((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
        } else {
          alert("Data is not an array", data);
        }
  
        dispatch(setReset());
      }catch (error) {
        const errorMessage = error.message || 'An error occurred';
        const statusCode = error.response ? error.response.status : 'No status code';
        setShowAlert(true);
        setAlertMessage(`Error uploading files: ${errorMessage} (Status Code: ${statusCode})`);
        console.error("Error uploading files:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setIsLogin(true);
    }
  };

  const postUploadFile = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      setShowAlert(true);
      setAlertMessage("Error uploading files:", error);
      throw error;
    }
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmitService = async (values, { resetForm }) => {
    if (profile.username !== "") {
      const formData = {
        serviceName: serviceName,
        fileTitle: values.documentName,
        fileDescription: values.description,
        filePaths: filePaths,
        QrCodePassword: values.password || "",
        userId: profile.userid,
      };

      try {
        if (filePaths.length >= 1) {
          const response = await axios.post(
            `${backendUrl}/purchaseQrCodeUser`,
            formData
          );
          const purchasedServiceId = response.data.url;
          if (purchasedServiceId) {
            dispatch(setInstanceId(purchasedServiceId));
          }
          setButtonText("Submitted");
        }

        resetForm();
        setFiles([]); // Clear the files array
        setFilePath([]); // Clear the file paths
        if (fileInputRef.current) {
          fileInputRef.current.value = ""; // Reset input field
        }
      } catch (error) {
        console.error("Error creating service instance:", error);
      }
    } else {
      setIsLogin(true);
    }
  };

  const BestPlans = useSelector((state)=>(state.Bestplans))

  useEffect(()=>{
    if((BestPlans.price!==null)&&(BestPlans.price!==0)){
    setAlertMessage(`Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`);
    setShowAlert(true);
    }
  },[BestPlans])


  


  return (
    <div className="max-w-lg mx-auto px-4 sm:px-6 lg:px-8">
      <h1 className="text-2xl font-bold text-center mb-6">Document Upload</h1>
      <Formik
        initialValues={{
          documentName: "",
          documentType: "",
          files: [],
          password: "",
        }}
        onSubmit={handleSubmitService}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-6 py-6 sm:px-8 sm:py-8 mb-4">
            {/* Document Name input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="documentName"
              >
                Document Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="documentName"
                placeholder="NA"
              />
            </div>

            {/* Document Type input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="description"
                placeholder="NA"
              />
            </div>

            {/* File input with validation */}
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="file"
              >
                Choose Document File(s)
              </label>
              <input
                id="file"
                name="file"
                type="file"
                 accept=".pdf"
                 multiple
                 onChange={(event) =>
                   chooseFileHandleChange(event, setFieldValue)
                 }
                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                 ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                    "#1D91AA",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>

            <div className="mt-2">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-2"
                  >
                    <span className="text-gray-700">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-customBlue" />
                    </button>
                  </div>
                ))}
              </div>
            {/* Password input with visibility toggle */}
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="NA"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute 
                                    md:right-[15px]
                                    top-1/2
                                    right-[-70px]
                                    text-gray-600 hover:text-gray-900"
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    size="1x"
                    color="#1D91AA"
                  />
                </button>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-between">
              <button
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: "#1D91AA" }}
                disabled={isSubmitting}
              >
                {buttonText}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showAlert && <CustomAlert message={alertMessage} onClose={() => { setShowAlert(false); }} />}
      {isLogin && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-8 w-96">
            {switchButton ? (
              <LoginUp setIsLogin={setIsLogin} setSwitchButton={setSwitchButton} />
            ) : (
              <SignUp setIsLogin={setIsLogin} setSwitchButton={setSwitchButton} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploadForm;
