import AudioUploadForm from "../Servicesforms/AudioUploadFrom"
import BusinessCardForm from "../Servicesforms/BussinessCardForm";
import DiscountCouponForm from '../Servicesforms/DiscountCouponForm';
import DocumentUploadForm from '../Servicesforms/DocumentUploadForm';
import EventForm from '../Servicesforms/EventForm';
import FeedbackForm from '../Servicesforms/FeedbackForm';
import ImageUploadForm from '../Servicesforms/ImageUploadForm';
import LocationUploadForm from '../Servicesforms/LocationUploadForm';
import MeetUploadForm from '../Servicesforms/MeetUploadForm';
import MenuCardForm from '../Servicesforms/MenuCardForm';
import MessageForm from '../Servicesforms/MessageForm';
import PatientForm from '../Servicesforms/PatientForm';
import UpiUploadForm from '../Servicesforms/UpiUploadForm';
import VideoUploadForm from '../Servicesforms/VideoUploadForm'; // Correct import
import WebsiteUploadForm from '../Servicesforms/WebsiteUploadForm';
import WifiUploadForm from "../Servicesforms/WifiUploadForm";
import PetIDForm from '../Servicesforms/PetIDForm'
import UrlForm from "../Servicesforms/UrlForm";
import LinkForm from "../Servicesforms/LinkForm";
import GoogleForm from "../Servicesforms/GoogleForm";
import GoogleLinkForm from "../Servicesforms/GoogleLinkForm";
import FaceBookForm from "../Servicesforms/FaceBookform";
import YouTubeForm from "../Servicesforms/YouTubeForm";
import WhatsAppForm from "../Servicesforms/WhatsAppform";
import ZoomMeetingForm from "../Servicesforms/ZoomMeetingForm";
import GoogleMeetForm from "../Servicesforms/GoogleMeetForm";
import ResumeForm from "../Servicesforms/ResumeForm";
import PdfForm from "../Servicesforms/PdfForm";
import TextForm from "../Servicesforms/TextForm";

const ServicesForQrCode = ({ name }) => { // Destructure name from props
    let serviceElement;
    switch (name) {
        case "Audio":
            serviceElement = <AudioUploadForm />;
            break;
        case "Business Card":
            serviceElement = <BusinessCardForm />;
            break;
        case "Discount":
            serviceElement = <DiscountCouponForm />;
            break;
        case "Document":
            serviceElement = <DocumentUploadForm />;
            break;
        case "Events":
            serviceElement = <EventForm />;
            break;
        case "FeedBack Forms":
            serviceElement = <FeedbackForm />;
            break;
        case "Image Gallery":
            serviceElement = <ImageUploadForm />;
            break;
        case "Location":
            serviceElement = <LocationUploadForm />;
            break;
        case "Meeting":
            serviceElement = <MeetUploadForm />;
            break;
        case "MenuCard":
            serviceElement = <MenuCardForm />;
            break;
        case "Sms":
            serviceElement = <MessageForm />;
            break;
        case "Medical":
            serviceElement = <PatientForm />;
            break;
        case "UPI":
            serviceElement = <UpiUploadForm />;
            break;
        case "Video":
            serviceElement = <VideoUploadForm />;
            break;
        case "Social Media":
            serviceElement = <WebsiteUploadForm />;
            break;
        case "Link":
            serviceElement = <LinkForm />;
            break;
        case "Google Form":
            serviceElement = <GoogleForm />;
            break;
        case "Wifi":
            serviceElement = <WifiUploadForm />
            break
        case "Pet Id Tag":
            serviceElement = <PetIDForm />
            break;
        case "Url":
            serviceElement = <UrlForm />
            break;
        case "Google Forms":
            serviceElement = <UrlForm />
            break;
        case "Google":
            serviceElement = <GoogleLinkForm />
            break;
        case "Facebook":
            serviceElement = <FaceBookForm />
            break;
        case "Youtube":
            serviceElement = <YouTubeForm />
            break;
        case "WhatsApp":
            serviceElement = <WhatsAppForm />
            break;
        case "Zoom Meeting":
            serviceElement = <ZoomMeetingForm/>
            break;
        case "Google Meet":
            serviceElement = <GoogleMeetForm/>
            break;
        case "Resume":
            serviceElement = <ResumeForm/>
            break;
        case "Pdf":
                serviceElement = <PdfForm/>
                break;
        case "Text":
                    serviceElement = <TextForm/>
                    break;
        default:
            serviceElement = <div>Service not found</div>; // Default case
            break;
    }

    return serviceElement;
}

export default ServicesForQrCode;
