import React, { useState, useRef, useEffect } from "react";
import CustomizationOptions from "./CustomizationOptions"; // Make sure to import your CustomizationOptions component
import { ColorRing } from "react-loader-spinner";
import { setReset } from "../store/downloadButtonRefernecSliceReducer";
import convertBitsToMb from "../convertbits/convertBitsToMb";
import convertBitsToGB from "../convertbits/convertbitstoGb";
// import convertBitsToTB from "../convertbits/convertBitsToTB";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "../CustomAlertForSubmision";

import {
  setPlanName,
  setPrice,
} from "../store/chooseBestOffers/BestOffersSlicer";

import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { setInstanceId } from "../store/ServiceSavedInstance/ServiceInstanceReducer";

const QrCodeGenerationForm = () => {
  const [loading, setLoading] = useState(false);
  const [buttonColor, setButtonColor] = useState("#e1ed8e");
  const [isHovered, setIsHovered] = useState(false);

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const [formData, setFormData] = useState({
    purpose: "",
    dataType: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [filePaths, setFilePath] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [buttonText, setButtonText] = useState("Submit");

  const [UrlName, setUrlName] = useState();
  const [UrlText, setUrlText] = useState();

  const dispatch = useDispatch();
  const [fileTitle, setFileTitle] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [QrCodePassword, setQrCodePassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState("");
  const [maxSizeCrosses,setMaxSizeCrossed] = useState(false);



  const devHost = '13.127.111.27'; // Use only the common part for both HTTP and HTTPS

  // Check if the window location starts with the devHost
  const backendUrl = window.location.origin.startsWith(`https://${devHost}`) || window.location.origin.startsWith(`http://${devHost}`)
      ? process.env.REACT_APP_API_DEV_URL
      : process.env.REACT_APP_API_PROD_URL;
  
  console.log('Backend URL:', backendUrl); 


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleInputChangeForServicesName = (event) => {
    setServiceName(event.target.value);
    setFileDescription("");
    setFileTitle("");
    setFilePath([]);
    setFiles([]);
    fileInputRef.current = null;
  };

  useEffect(() => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0); // Total size in bytes
    if (totalSize > 0 && totalSize <= 73400320) {
      const plan = convertBitsToMb(totalSize);
      dispatch(setPlanName(plan.name));
      dispatch(setPrice(plan.price));
      setMaxSizeCrossed(false);
      console.log(totalSize)
    // } else if (totalSize > 52428800 && totalSize <= 73400320) {
    //   const plan = convertBitsToGB(totalSize);
    //   setMaxSizeCrossed(false);
    //   dispatch(setPlanName(plan.name));
    //   dispatch(setPrice(plan.price));
    // }
    }
      if (totalSize > 73400320) {
      setMaxSizeCrossed(true);
      setAlertMessage("currently we provides the file size upto 70mb");
      setShowAlert(true);
    }
  }, [files, dispatch]);

  const MAX_FILE_SIZE_MB = 70; // Maximum file size in MB
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes
  
  let oversizedFiles = []; // Declare outside to persist across function calls
  
  const chooseFileHandleChange = async (event) => {

    setButtonColor("#517b7b");
    setButtonText("Submit");
    setLoading(true);
  
    const selectedFiles = Array.from(event.target.files);
    const validFiles = []; // Temp array for new valid files
    oversizedFiles = []; // Reset the oversizedFiles array for each new selection
  
    // Filter valid files and collect oversized file names
    selectedFiles.forEach((file) => {
      if (file.size <= MAX_FILE_SIZE_BYTES) {
        validFiles.push(file); // Add valid new files
      } else {
        oversizedFiles.push(file.name); // Collect oversized file names
      }
    });
  
    // Show alert if there are any oversized files
    if (oversizedFiles.length > 0) {
      setAlertMessage(
        `The following files exceed the size limit of ${MAX_FILE_SIZE_MB} MB and will not be uploaded: ${oversizedFiles.join(", ")}.`
      );
      setShowAlert(true);
    }
  
    // If no valid files, stop further execution
    if (validFiles.length === 0) {
      setLoading(false); // Stop the loading state if there are no valid files
      return;
    }
  
    // Add the newly selected valid files to the existing files in state
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  
    const formData = new FormData();
    // Append all the files (previously uploaded + newly selected)
    [...files, ...validFiles].forEach((file) => {
      formData.append("file", file);
    });
  
    try {
      const uploadData = await postUploadFile(
        `${backendUrl}/upload`,
        formData
      );

      const data = uploadData.data;

      if (Array.isArray(data)) {
        const newFilePaths = data.map((item) => item.url);

        setFilePath((prevFilePaths) => [...prevFilePaths, ...newFilePaths]);
      } else {
        alert("Data is not an array", data);
      }

      dispatch(setReset());
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setLoading(false);
    }
  };
  
  

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i !== index);
      // Remove corresponding file path
      setFilePath((prevFilePaths) =>
        prevFilePaths.filter((_, i) => i !== index)
      );
      return updatedFiles;
    });

    // Clear file input if it was the last file
    if (files.length === 1 && fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const postUploadFile = async (url, formData) => {
    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error during upload:", error);
         setShowAlert(true);
      setAlertMessage("Error uploading files:", error);
      throw error;
    }
  };

  const profile = JSON.parse(localStorage.getItem("registeredUserData"));

  const handleSubmitService = async () => {
    setButtonText("Submitted");
    const formData = {
      serviceName: serviceName,
      fileTitle: fileTitle,
      fileDescription: fileDescription,
      filePaths: filePaths,
      QrCodePassword: QrCodePassword || "",
      userId: profile.userid,
    };

    try {
      if (filePaths.length >= 1) {
        const response = await axios.post(
          `${backendUrl}/purchaseQrCodeUser`,
          formData
        );
        const purchasedServiceId = response.data.url;
        if (purchasedServiceId) {
          dispatch(setInstanceId(purchasedServiceId));
        }
        setButtonText("Submitted");
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset input field
      }
    } catch (error) {
      console.error("Error creating service instance:", error);
    }
  };

  // Form submission handler
  const handleSubmitForUrl = async () => {
    if (profile.userid !== "") {
      const formData = {
        serviceName: serviceName,
        UrlName: UrlName,
        UrlText: UrlText,
        QrCodePassword: QrCodePassword,
        userId: profile.userid,
      };

      try {
        const response = await axios.post(
          `${backendUrl}/purchaseQrCodeUserForUrl`,
          formData
        );
        const purchasedServiceUrl = response.data.url;

        dispatch(setInstanceId(purchasedServiceUrl));
        setButtonText("Submitted");
        dispatch(setPrice(99));
        dispatch(setPlanName("Basic Starter plan"));
      } catch (error) {
        console.error(error);
      }
    }
  };

  
  const BestPlans = useSelector((state)=>(state.Bestplans))

  useEffect(()=>{
    if((BestPlans.price!==null)&&(BestPlans.price!==0)){
    setAlertMessage(`Your current Plan ${BestPlans.planName} and price:${BestPlans.price}`);
    setShowAlert(true);
    }
  },[BestPlans])



  return (
    <div className="max-w-md mx-auto p-6 rounded-md shadow-md bg-customBlue font-raleway">
      <form
        onSubmit={(e) => {
          e.preventDefault(); /* Handle form submission */
        }}
      >
        <div className="mb-4">
          <label className="block text-gray-700">Purpose</label>
          <select
            name="purpose"
            value={formData.purpose}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border rounded-md"
          >
            <option value="">Select Purpose</option>
            <option value="Personal">Personal</option>
            <option value="Business">Business</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Choose Data Type</label>
          <select
            name="dataType"
            value={serviceName}
            onChange={handleInputChangeForServicesName}
            className="w-full mt-1 p-2 border rounded-md"
          >
            <option value="">Search Data Type</option>
            <option value="Audio">Audio</option>
            <option value="Video">Video</option>
            <option value="Image">Image</option>
            <option value="Document">Document</option>
            <option value="URL">Social Media Links</option>
            <option value="Others">Others</option>
          </select>
        </div>

        {serviceName === "Audio" && (
          <div className="mb-4">
            <h2 className="text-xl">Audio Form</h2>
            <label className="block text-gray-700">Audio Title</label>
            <input
              type="text"
              name="audioTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Audio Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept="audio/*"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button" // Ensures this button does not act as a submit button
                      onClick={() => removeFile(index)} // Calls removeFile function with the file index
                      className="text-red-500 hover:text-red-700"
                      aria-label={`Remove file at index ${index}`} // Accessibility improvement
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              { (!loading && (!maxSizeCrosses))&& (
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}
        {serviceName === "Video" && (
          <div className="mb-4">
            <h2 className="text-xl">Vedio Form</h2>
            <label className="block text-gray-700">Vedio Title</label>
            <input
              type="text"
              name="VedioTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Vedio Files
              </label>

              <input
                id="files"
                name="files"
                type="file"
                accept="video/*"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading && (!maxSizeCrosses))&&(
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}

        {serviceName === "Image" && (
          <div className="mb-4">
            <h2 className="text-xl">Image Form</h2>
            <label className="block text-gray-700">Image Title</label>
            <input
              type="text"
              name="ImageTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Image Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {(!loading && (oversizedFiles.length===0))&& (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading && (oversizedFiles.length===0))&& (
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}

        {serviceName === "Document" && (
          <div className="mb-4">
            <h2 className="text-xl">Document Form</h2>
            <label className="block text-gray-700">Document Title</label>
            <input
              type="text"
              name="DocumentTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Document Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept=".pdf"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading && (oversizedFiles.length===0))&&(
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText}
                </button>
              )}
            </div>
          </div>
        )}
        {serviceName === "Others" && (
          <div className="mb-4">
            <h2 className="text-xl">Others Form</h2>
            <label className="block text-gray-700">Others Title</label>
            <input
              type="text"
              name="audioTitle"
              value={fileTitle}
              onChange={(e) => setFileTitle(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />

            <label className="block text-gray-700">Description</label>
            <textarea
              name="description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="files"
              >
                Choose Other Files
              </label>
              <input
                id="files"
                name="files"
                type="file"
                accept="image/png, image/jpeg , image/jpg"
                multiple
                onChange={(event) => chooseFileHandleChange(event)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ref={fileInputRef}
              />
              {loading && (
                <ColorRing
                  visible={true}
                  height="40"
                  width="30"
                  ariaLabel="comment-loading"
                  wrapperStyle={{}}
                  wrapperClass="comment-wrapper"
                  colors={[
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                    "#517b7b",
                  ]} // This applies the same color to all circles
                />
              )}
            </div>
            <div className="mt-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between mt-2"
                >
                  <span className="text-white">{file.name}</span>
                  {!loading && (
                    <button
                      type="button"
                      onClick={() => removeFile(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FontAwesomeIcon icon={faTimes} className="text-red" />
                    </button>
                  )}
                </div>
              ))}
            </div>

            <div className="mb-4 relative">
              <label className="block text-gray-700">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={QrCodePassword}
                onChange={(e) => setQrCodePassword(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div className="flex justify-between items-center p-4">
              {(!loading && (oversizedFiles.length===0))&& (
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitService}
                >
                  {buttonText !== "" ? buttonText : "Submit"}
                </button>
              )}
            </div>
          </div>
        )}

        {serviceName === "URL" && (
          <div className="mb-4">
            <h2 className="text-xl">Links Form</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Web-SiteTitle</label>
              <input
                type="text"
                name="Urltitle"
                value={UrlName}
                onChange={(e) => setUrlName(e.target.value)}
                placeholder="NA"
                className="w-full mt-1 p-2 border rounded-md"
              />
            </div>
            <label className="block text-gray-700">URL</label>
            <input
              type="text"
              name="url"
              value={UrlText}
              onChange={(e) => {
                setUrlText(e.target.value);
                setButtonText("Submit");
              }}
              placeholder="NA"
              className="w-full mt-1 p-2 border rounded-md"
            />
            <div className="mb-6">
              <div className="mb-4 relative">
                <label className="block text-gray-700">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={QrCodePassword}
                  onChange={(e) => setQrCodePassword(e.target.value)}
                  placeholder="NA"
                  className="w-full mt-1 p-2 border rounded-md"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-[15px] top-1/2 text-gray-600 hover:text-gray-900"
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>
              <div className="flex justify-between items-center p-4">
                <button
                  type="submit"
                  style={{
                    backgroundColor: isHovered ? "white" : buttonColor, // Change background to white on hover
                    color: isHovered ? buttonColor : "white", // Change text color to buttonColor when hovered
                  }}
                  className={`${
                    buttonText === "submit" ? "w-20" : "w-40"
                  } p-2 m-2 shadow-lg rounded-md transition-all duration-300 transform ${
                    isHovered ? "scale-105" : "scale-100" // Scale effect on hover
                  }`}
                  onMouseEnter={() => setIsHovered(true)} // Set hover state
                  onMouseLeave={() => setIsHovered(false)} // Reset hover state
                  onClick={handleSubmitForUrl}
                >
                  {buttonText}
                </button>
              </div>
            </div>
          </div>
        )}
      </form>

      {showAlert && (
        <CustomAlert
          message={alertMessage}
          onClose={() => {
            setShowAlert(false);
          }}
        />
      )}

      <CustomizationOptions serviceName={serviceName} />
    </div>
  );
};

export default QrCodeGenerationForm;
