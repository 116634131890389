import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPencil, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import useMediaQuery from "../usematchqueryfortrackscreensize/useMediaQuery";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomAlert from "../CustomAlertForSubmision";
import useLoadScript from "../reusableHook/useLoadScript";

const MyQRCodeProfile = () => {
  const { loaded, error } = useLoadScript(
    "https://checkout.razorpay.com/v1/checkout.js"
  );
  const [password, setPassword] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(false);
  const [servicesData, setServicesData] = useState([]); // State to store services data
  const isMobile = useMediaQuery("(max-width: 960px)");
  const userProfile = useSelector((state) => state.profile);
  const [changeInServices, setChangeService] = useState(false);
  const [id, setChooseServiceId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [serviceName, setServiceName] = useState("");

const devHost = '13.127.111.27'; // Use only the common part for both HTTP and HTTPS

// Check if the window location starts with the devHost
const backendUrl = window.location.origin.startsWith(`https://${devHost}`) || window.location.origin.startsWith(`http://${devHost}`)
    ? process.env.REACT_APP_API_DEV_URL
    : process.env.REACT_APP_API_PROD_URL;

  console.log("Backend URL:", backendUrl);
  
  useEffect(() => {
    const getServicesData = async () => {
      const userid = { userid: userProfile.userid };
      try {
        const response = await axios.post(
          `${backendUrl}/findAllServicesPuchasedByUsers`,
          userid
        );
        setServicesData(response.data); // Update state with fetched data
        setChangeService(false);
      } catch (error) {
        console.error("Error fetching services data:", error);
      }
    };

    if (userProfile.userid) {
      getServicesData();
    }
  }, [userProfile, changeInServices]);

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmitPassword = async () => {
    if (selectedPlan) {
      if (!password) {
        setShowAlert(true);
        setAlertMessage("Password cannot be empty.");
        return;
      }

      const changePassword = { id, password };

      try {
        const endpoint =
          serviceName === "Url"
            ? "changepasswordforprotectedOnesForUrl"
            : "changepasswordforprotectedOnes";

        const response = await axios.post(
          `${backendUrl}/${endpoint}`,
          changePassword
        );
        if (response.status === 200) {
          setShowAlert(true);
          setAlertMessage("Your QR Code password has been changed successfully.");
          setPassword(""); // Clear password field after successful change
        } else {
          setShowAlert(true);
          setAlertMessage("Failed to change the password. Please try again.");
        }
      } catch (error) {
        setShowAlert(true);
        setAlertMessage(`Error: ${error.message}`);
      } finally {
        setSelectedPlan(false); // Reset selectedPlan
      }
    }
  };

  const handleDelete = async (service) => {
    try {
      const serviceId = service.id;
      const endPoint =
        service.serviceName === "Url"
          ? "deleteserviceByIdForUrl"
          : "deleteserviceById";
      const response = await axios.delete(
        `${backendUrl}/${endPoint}?id=${serviceId}`
      );
      console.log("Service deleted successfully:", response.data); // Log the response

      // Update state to reflect deletion
      setServicesData(servicesData.filter((item) => item.id !== serviceId));
    } catch (error) {
      console.error("Service deletion failed:", error.message);
    }
  };

  const EditPasswordOFQrCode = (service) => {
    const serviceId = service.id;
    setServiceName(service.serviceName);
    setSelectedPlan(true);
    setChooseServiceId(serviceId);
  };

  const handlePayment = async (service) => {
    const serviceid = service.id;
    if (!loaded) {
      alert("Razorpay SDK is still loading...");
      return;
    }

    // Check if there was an error loading Razorpay script
    if (error) {
      alert("Failed to load Razorpay SDK. Please try again.");
      return;
    }
    const options = {
      key: "rzp_live_LwthtEns6Yq7za",
      amount: service.serviceName!=='Url'?999 * 100:99*100, // 99 INR in paise
      currency: "INR",
      name: `Annual renewal price 99 rs for ONE GB`,
      description: "Test Transaction for QR Code",
      handler: async function (response) {
        alert("Payment Successful! Payment ID: " + response.razorpay_payment_id);

        const data = { id: serviceid };
        try {
          const endPoint =
            service.serviceName === "Url"
              ? "renewServiceForUrl"
              : "renewService";

          const responseData = await axios.post(
            `${backendUrl}/${endPoint}`,
            data
          );

          if (responseData.status === 200) {
            setAlertMessage("Your Qr is renewed");
            setShowAlert(true);
            setChangeService(true);
          } else {
            console.error("Service renewal failed:", responseData.status);
          }
        } catch (error) {
          setAlertMessage(error.message);
          setShowAlert(true);
        }
      },
      prefill: {
        name: userProfile.username,
        email: userProfile.email,
      },
      theme: {
        color: "#F37254",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="p-4 border-2 border-transparent justify-center h-[max-fit]">
      {servicesData.length === 0 ? (
        <div className="text-center font-raleway text-lg text-gray-500">
          No QR Code Purchased
        </div>
      ) : (
        <ul className={`${isMobile ? "flex flex-col" : "flex flex-wrap"}`}>
          {servicesData.map((service) => (
            <li
              key={service.id}
              className={`${
                isMobile ? "w-full" : "w-[25%]"
              } p-2 m-2 border-2 border-[#f0ebeb] shadow-lg`}
            >
              <img
                src={`${backendUrl}/getUploadForUserByFileId/${service.QrCodeImage}`}
                alt={service.serviceName}
                className="w-full h-auto"
              />
              <h3 className="font-raleway text-lg m-4 font-bold text-center">
                {service.serviceName}
              </h3>
              <div className="flex flex-row items-center justify-between px-4">
                <p className="font-raleway text-base font-semibold flex items-center">
                  <span className="ml-2">{service.scans || 0} Scans</span>
                </p>

                {service.QrCodePassword !== "" && (
                  <div className="flex flex justify-between items-center">
                    <p className="font-raleway text-[13px] font-semibold">
                      Change Password
                    </p>
                    <button
                      className="border-none p-0 m-0 focus:outline-none"
                      aria-label="Edit"
                      onClick={() => EditPasswordOFQrCode(service)}
                    >
                      <FontAwesomeIcon
                        icon={faPencil}
                        className="h-[13px] w-[13px] pl-[5px] text-[#4bdede]"
                      />
                    </button>
                  </div>
                )}
              </div>
              <p className="font-raleway text-base m-4 font-bold text-center">
                Expires on: {service.QrCodeExpiryDate}
              </p>
              <div className="flex justify-around mt-4">
                <div
                  onClick={() => handlePayment(service)}
                  className="flex items-center cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="h-[16px] w-[20px] p-2 bg-[#4bdede] rounded-full text-white border-2 border-white"
                  />
                  <span className="font-raleway text-[16px] ml-2">Renew</span>
                </div>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => handleDelete(service)}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="h-[16px] w-[20px] p-2 bg-[#f55656] rounded-full text-white border-2 border-white"
                  />
                  <span className="font-raleway text-[16px] ml-2">Delete</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}

      {selectedPlan && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-[#1D91AA] text-white p-6 rounded-lg shadow-lg w-80">
            <label className="font-raleway text-300 m-4 font-bold text-white">
              Password
            </label>
            <input
              type="password"
              className="border-2 border-[#f0ebeb] rounded-lg p-4 text-black"
              placeholder="Enter Your QR Code Password"
              value={password}
              onChange={handleChange}
            />
            <button
              className="bg-white text-customBlue hover:bg-customBlue hover:text-white font-bold py-2 px-4 rounded flex flex-start w-[65px] m-[16px]"
              onClick={handleSubmitPassword}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {showAlert && (
        <CustomAlert message={alertMessage} onClose={() => setShowAlert(false)} />
      )}
    </div>
  );
};

export default MyQRCodeProfile;
