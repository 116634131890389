import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../store/QrCodeCustmizable/QrCodecustmizableSlicer'; // Ensure the import path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const WifiUploadForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const qrCodeData = useSelector((state) => state.QrCodeCustmizable?.data || 'Default QR Code Data');

  useEffect(() => {
    console.log(qrCodeData + " profileData");
  }, [qrCodeData]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">QR Code Generator for WiFi</h1>
      <Formik
        initialValues={{
          wifiName: '',
          wifiSecurity: '',
          passcodeOrUrl: '',
          password: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const { wifiName, wifiSecurity, passcodeOrUrl } = values;

          // Generate the WiFi QR code string
          const wifiString = `WIFI:T:${wifiSecurity};S:${wifiName};P:${passcodeOrUrl};`;
          dispatch(setData(wifiString));
          
          // Handle form submission logic here
          setSubmitting(false);
          console.log(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* WiFi Name input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="wifiName">
                WiFi Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="wifiName"
                placeholder="Enter WiFi name"
              />
            </div>

            {/* WiFi Security Type input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="wifiSecurity">
                WiFi Security Type
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="wifiSecurity"
                placeholder="Enter WiFi Security Type"
              />
            </div>

            {/* Passcode/URL input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="passcodeOrUrl">
                Enter WiFi Passcode
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="passcodeOrUrl"
                placeholder="Enter WiFi passcode"
              />
            </div>

            {/* Password input */}
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <div className="">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="NA"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute 
                  md:right-[15px]
                  top-1/2
                  right-[-70px]
                  text-gray-600 hover:text-gray-900">
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                </button>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-between">
              <button
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: '#1D91AA' }}
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default WifiUploadForm;
