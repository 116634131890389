import { useLocation } from 'react-router-dom';
import MyProfile from './MyProfile';
import styles from '../module/navbar.module.css'
import { useNavigate } from 'react-router-dom';
import MyQRCodeProfile from './MyQRCodeProfile';

const UserProfileContent = () => {
    const location = useLocation();
    const param = location.pathname;
    const navigate = useNavigate();
    let content;

    switch (param) {
        case '/userprofile':
            content = <MyProfile className="flex flex-col w-[60%] m-4 rounded-lg h-auto items-center p-2 shadow-lg"/>;
            break;
         case '/userprofile/qrcode':
            
            content = <MyQRCodeProfile  className="flex flex-col w-[60%] m-4 rounded-lg h-auto items-center p-2 shadow-lg"/>;
            break;
        default:
             // Fallback component
             navigate('/')
            break;
    }

    return (
        <div className={styles.userProfileDashBoardContent}>
            {content}
        </div>
    );
};

export default UserProfileContent;
