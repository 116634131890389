import React from 'react';
import NavBar from "../NavBar";
import AboutUsHomePage from "../AboutUsHomePage";
import ContactUs from "../ContactUs";
import Footer from "../Footer";


export default function Industry() {
    const enterprisePrices = [
        { range: "Required Storage Database", price: "The intention of discussing a possible price for industries benefit" },
    ];
    
    return (
        <>
            <NavBar />
            <div className="text-center font-raleway flex flex-col p-5 flex justify-center items-center shadow-[0_5px_15px_rgba(0,0,0,0.35)] m-4 rounded-[11px]">
                <h1 className="text-2xl text-blue-900 mb-5">Pricing Plans</h1>
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-xl text-blue-800 mb-3">Industry</h2>
                    <table className="min-w-full border-collapse bg-blue-50">
                        <thead>
                            <tr>
                                <th className="border border-blue-200 bg-blue-100 text-blue-900 p-3" scope="col">Storage</th>
                                <th className="border border-blue-200 bg-blue-100 text-blue-900 p-3" scope="col">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enterprisePrices.map((item, index) => (
                                <tr key={index} className={index % 2 === 0 ? 'bg-blue-100' : 'bg-blue-50'}>
                                    <td className="border border-blue-200 p-3">{"1 TB"}</td>
                                    <td className="border border-blue-200 p-3">{"50000"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <AboutUsHomePage />
            <ContactUs />
            <Footer />
        </>
    );
}
