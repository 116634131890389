import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faTimes } from '@fortawesome/free-solid-svg-icons';

const PetIDForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">Pet ID Details</h1>
      <Formik
        initialValues={{
          petPhotos: [],
          petName: '',
          breed: '',
          age: '',
          food: '',
          ownerName: '',
          mobileNo: '',
          address: '',
          password: ''
        }}
        onSubmit={(values) => {
          // Handle form submission
          console.log(values);
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Pet Photos input (Multiple) */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petPhotos">
                Pet Photos
              </label>
              <input
                id="petPhotos"
                name="petPhotos"
                type="file"
                accept="image/*"
                multiple
                onChange={(event) => {
                  const selectedFiles = Array.from(event.currentTarget.files);
                  // Append new files to the existing array of files
                  setFieldValue('petPhotos', [...values.petPhotos, ...selectedFiles]);
                }}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            {/* Display uploaded files with delete option */}
            {values.petPhotos.length > 0 && (
              <div className="mb-4">
                <ul>
                  {values.petPhotos.map((file, index) => (
                    <li key={index} className="flex items-center justify-between text-gray-700 text-xs italic mb-2">
                      {file.name}
                      <button
                        type="button"
                        onClick={() => {
                          const updatedFiles = values.petPhotos.filter((_, i) => i !== index);
                          setFieldValue('petPhotos', updatedFiles);
                        }}
                        className="ml-2 text-red-500 hover:text-red-700"
                      >
                        <FontAwesomeIcon icon={faTimes} className="text-customBlue" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Pet Name input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="petName">
                Pet Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="petName"
                placeholder="NA"
              />
            </div>

            {/* Other fields like breed, age, food, ownerName, etc. */}
            {/* Breed input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="breed">
                Breed
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="breed"
                placeholder="NA"
              />
            </div>

            {/* Age input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="age">
                Age
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="age"
                placeholder="NA"
              />
            </div>

            {/* Food input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="food">
                Food
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="food"
                placeholder="NA"
              />
            </div>

            {/* Owner Name input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="ownerName">
                Owner Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="ownerName"
                placeholder="NA"
              />
            </div>

            {/* Mobile No input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="mobileNo">
                Mobile No
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="mobileNo"
                placeholder="NA"
              />
            </div>

            {/* Address input */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
                Address
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="address"
                placeholder="NA"
              />
            </div>

            {/* Password input with visibility toggle */}
            <div className="mb-4 relative">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Password
              </label>
              <div className="">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="NA"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute 
                                    md:right-[15px]
                                    top-1/2
                                    right-[-70px]
                                    text-gray-600 hover:text-gray-900">
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} size="1x" color="#1D91AA" />
                </button>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-between">
              <button
                className=" text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: '#1D91AA' }}
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PetIDForm;
