import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faGlobe } from "@fortawesome/free-solid-svg-icons";

// Validation schema for password only
const LocationFormSchema = Yup.object().shape({
  placeName: Yup.string().required("Place name is Required"),
  password: Yup.string().required("Password is required"),
});

localStorage.removeItem('price');
localStorage.removeItem('planName');

const LocationUploadForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const setLink = (setFieldValue) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const url = `https://www.google.com/maps/?q=${latitude},${longitude}`;
          setFieldValue("url", url); // Update Formik field value
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="max-w-lg mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">
        Location Details Upload
      </h1>
      <Formik
        initialValues={{
          placeName: "",
          url: "",
          password: "",
        }}
        validationSchema={LocationFormSchema}
        onSubmit={(values) => {
          // Handle form submission
          console.log(values);
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            {/* Place Name input */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="placeName"
              >
                Place Name
              </label>
              <Field
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                name="placeName"
                placeholder="NA"
              />
            </div>

            {/* URL input */}
            <div className="mb-4 flex items-center justify-center">
              <div className="flex flex-col ">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="url"
                >
                  URL
                </label>
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="url"
                  placeholder="NA"
                />
              </div>
              <button
                type="button"
                className="border-none flex justify-center items-center mt-5 p-[5px]"
                onClick={() => setLink(setFieldValue)}
              >
                <FontAwesomeIcon icon={faGlobe} size="1x" color="#1D91AA" />
              </button>
            </div>

            {/* Password input with validation */}
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="">
                <Field
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="NA"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute 
                                    md:right-[15px]
                                    top-1/2
                                    right-[-70px]
                                    text-gray-600 hover:text-gray-900"
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    size="1x"
                    color="#1D91AA"
                  />
                </button>
              </div>
            </div>

            {/* Submit button */}
            <div className="flex items-center justify-between">
              <button
                className="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                style={{ backgroundColor: "#1D91AA" }}
                disabled={isSubmitting}
              >
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LocationUploadForm;
